<template>
  <div
    class="tab-pane fade"
    id="general-setting"
    role="tabpanel"
    aria-labelledby="general-setting-tab"
  >
    <WeCard>
      <!-- Card Title -->
      <div class="row align-items-center">
        <div class="col">
          <h5 class="mb-0">Genel Ayarlar</h5>
        </div>
        <div class="col-auto">
          <!-- Submit -->
          <div class="text-center">
            <span class="btn btn-success" v-on:click="onSave"
              ><i class="fas fa-save"></i> Kaydet</span
            >
          </div>
          <!-- ./Submit -->
        </div>
      </div>
      <!-- ./Card Title -->
      <hr />

      <div class="row">
        <div class="col-12 col-lg-6">
          <!-- Website Status -->
          <WeSettingCard
            name="site-status"
            title="Site Durumu"
            description="Site durumu pasif ise yapım aşaması bilgisi görünür"
            v-model="data.status"
          />
          <!-- Website Status -->

          <!-- CDN -->
          <WeSettingCard
            name="cdn-enable"
            title="CDN Kullanımı"
            description="CDN sistemi kullanılıp kullanılmayacağı belirlenir"
            v-bind:disabled="true"
            v-model="data.cdn_enable"
          />
          <!-- ./CDN -->

          <!-- Credit Card -->
          <WeSettingCard
            name="credit-card"
            title="Kredi Kartı Kullanımı"
            description="Sitede Kredi Kart ile alışverişi etkinleştirir veya devre dışı bırakır"
            v-model="data.credit_card"
          />
          <!-- Credit Card -->

          <!-- Credit Card -->
          <WeSettingCard
            name="bank-transfer"
            title="Havale Kullanımı"
            description="Sitede Havale ile alışverişi etkinleştirir veya devre dışı bırakır"
            v-model="data.bank_transfer"
          />
          <!-- Credit Card -->

          <!-- Sales Contract -->
          <WeSettingCard
            name="sales-contract"
            title="Satış Sözleşmesi"
            description="Satış Sözleşmesini göster"
            v-model="data.sales_contract"
          />
          <!-- Sales Contract -->

          <!-- Show Price -->
          <WeSettingCard
            name="show-price"
            title="Ürün Fiyatları"
            description="Ürünlerin fiyatlarını göster"
            v-model="data.show_price"
          />
          <!-- Show Price -->

          <!-- Show Tax Excluded -->
          <WeSettingCard
            name="show-tax-excluded"
            title="KDV Hariç Fiyatları Göster"
            v-model="data.show_tax_excluded"
          />
          <!-- Show Tax Excluded -->

          <!-- Show Out of Stock -->
          <WeSettingCard
            name="show-out-stock"
            title="Stokta Olmayan Ürünler"
            description="Stokta olmayan ürünlerin sipariş verilmesine izin ver"
            v-model="data.order_out_stock"
          />
          <!-- Show Out of Stock -->

          <!-- API Update Price -->
          <WeSettingCard
            name="api-price-update"
            title="Satış Fiyatlarını API'dan güncelle"
            description="Satış Fiyatlarını API'dan güncelle"
            v-model="data.api_update_price"
          />
          <!-- API Update Price -->
          <template v-if="data.api_update_price">
            <div class="form-row align-items-center my-2">
              <div class="col-12 col-lg">
                <label for="api-price-url" class="custom-label"
                  >Fiyat API Adresi</label
                >
                <div class="d-block">
                  <small class="text-muted">Satış fiyatları API Adresi</small>
                </div>
              </div>
              <div class="col-12 col-lg-6">
                <WeInput
                  type="text"
                  id="api-price-url"
                  placeholder="URL"
                  v-model="data.price_api_url"
                  v-bind:formGroup="false"
                />
              </div>
            </div>
            <div class="form-row align-items-center mb-2">
              <div class="col-12 col-lg">
                <label for="insurance-cost" class="custom-label"
                  >API Fiyat Limiti</label
                >
                <div class="d-block">
                  <small class="text-muted"
                    >API üzerinden gelen fiyat, ürünün fiyatından %X düşük ise
                    güncelleme yapmaz.<br />
                    Limit kullanmak istemiyorsanız boş bırakın.</small
                  >
                </div>
              </div>
              <div class="col-12 col-lg-2">
                <WeInput
                  class="mb-0"
                  v-bind:prepend="'%'"
                  v-model="data.api_price_limit"
                  v-bind:filter-number="true"
                  name="api-price-limit"
                />
              </div>
            </div>
            <!-- <div class="form-row align-items-center mb-2">
              <div class="col-12 col-lg">
                <label for="insurance-cost" class="custom-label"
                  >Sigorta Poliçesi Sabit Masrafı</label
                >
              </div>
              <div class="col-12 col-lg-6">
                <WeInput
                  class="mb-0"
                  v-bind:prepend="'₺'"
                  v-model="data.insurance_fixed_cost"
                  v-bind:currency="true"
                  name="insurance-cost"
                  ref="insuranceCost"
                />
              </div>
            </div> -->
          </template>

          <!-- Show Featured Product -->
          <WeSettingCard
            name="show-featured-product"
            title="Öne Çıkan Ürünleri Göster"
            v-model="data.show_featured_product"
          />
          <!-- Show Featured Product -->

          <!-- Show New Product -->
          <WeSettingCard
            name="show-new-product"
            title="Yeni Ürünleri Göster"
            v-model="data.show_new_product"
          />
          <!-- Show New Product -->

          <!-- Without Images -->
          <WeSettingCard
            name="not-image"
            title="Resimsiz Ürünler"
            description="Görseli olmayan ürünleri listeleme (Aktif ise listelenmez)"
            v-model="data.not_image"
          />
          <!-- Without Images -->

          <!-- Delivery Zone -->
          <WeSettingCard
            name="show-delivery-zone"
            title="Teslimat Bölgeleri"
            description="Sadece belirlenen bölgelere sipariş verilebilir"
            v-model="data.show_delivery_zone"
          />
          <!-- Delivery Zone -->

          <!-- Slug Prefix -->
          <WeSettingCard
            name="slug-prefix"
            title="URL Ön Eki"
            description="Detay sayfalarında ön ek kullan"
            v-model="data.slug_prefix"
          />
          <!-- ./Slug Prefix -->
        </div>

        <div class="col-12 col-lg-6">
          <!-- Comment -->
          <WeSettingCard
            name="comment"
            title="Site/Ürün Yorumları"
            description="Site/Ürün hakkında yorum yapılmasına izin ver"
            v-model="data.comment"
          />
          <!-- Comment -->

          <!-- Only Members -->
          <WeSettingCard
            v-if="data.comment"
            name="comment-member"
            title="Yalnız Üyeler Yorum Yapabilir"
            v-model="data.comment_member"
          />
          <!-- Only Members -->

          <!-- Point -->
          <WeSettingCard
            name="point"
            title="Ürün Puanlaması"
            description="Ürün puanlamasına izin ver"
            v-model="data.point"
          />
          <!-- Point -->

          <!-- Variation -->
          <WeSettingCard
            name="variation"
            title="Ürün Varyasyonları"
            description="Ürünlere ait varyasyon eklemeye izin ver"
            v-model="data.product_variants"
          />
          <!-- Variation -->

          <!-- Feature Values -->
          <WeSettingCard
            name="feature-values"
            title="Özellik Değerleri"
            description="Özellik değerlerini ara/listele (Aktif ise listeleme yapar)"
            v-model="data.show_feature_values"
          />
          <!-- Feature Values -->

          <!-- Mobil -->
          <WeSettingCard
            name="mobile"
            title="Mobil Uyum"
            description="Siteyi mobil uyumlu olarak göster"
            v-model="data.mobile"
          />
          <!-- Mobil -->

          <!-- KVKK -->
          <WeSettingCard
            name="kvkk"
            title="KVKK"
            description="KVKK metnini göster"
            v-model="data.kvkk"
          />
          <!-- KVKK -->

          <!-- Cash on Delivery -->
          <WeSettingCard
            name="cash-on-delivery"
            title="Kapıda Ödeme Kullan"
            description="Kapıda Ödeme kullan"
            v-model="data.cash_on_delivery"
          />
          <!-- Cash on Delivery -->

          <!-- Use Coupon Code-->
          <WeSettingCard
            name="use-coupon"
            title="Hediye Çeki Kullan"
            description="Hediye Çeki Kullan"
            v-model="data.use_coupon"
          />
          <!-- Use Coupon Code-->

          <!-- ERP -->
          <WeSettingCard
            name="erp-integration"
            title="ERP Entegrasyonu Kullan"
            description="ERP Entegrasyonu Kullan"
            v-bind:disabled="true"
            v-model="data.erp_integration"
          />
          <!-- ERP -->

          <!-- Marketplace Integration -->
          <WeSettingCard
            name="marketplace-integration"
            title="Pazaryeri Entegrasyonu Kullan"
            description="Pazaryeri Entegrasyonu Kullan"
            v-bind:disabled="true"
            v-model="data.marketplace_integration"
          />
          <!-- Marketplace Integration -->

          <!-- Invoice Integration -->
          <WeSettingCard
            name="invoice-integration"
            title="E-Fatura Entegrasyonu Kullan"
            description="E-Fatura Entegrasyonu Kullan"
            v-bind:disabled="true"
            v-model="data.invoice_integration"
          />
          <!-- Invoice Integration -->

          <!-- Get Price From Marketplace -->
          <WeSettingCard
            name="invoice-integration"
            title="Pazaryeri Fiyatlarını Göster"
            description="Ürün detayda pazaryeri fiyatlarını göster/gösterme"
            v-bind:disabled="true"
            v-model="data.show_marketplace_price"
          />
          <!-- Get Price From Marketplace -->
          <!-- Get Price From Marketplace -->
          <WeSettingCard
            name="micro-site-status"
            title="Mikro Site Kullanımı"
            description="Mikro site kullanımı Aktif/Pasif"
            v-bind:disabled="false"
            v-model="data.micro_site_status"
          />
          <!-- Get Price From Marketplace -->
        </div>
      </div>

      <hr />

      <div class="row">
        <!-- VAT -->
        <div class="col-12 col-lg-6">
          <div class="form-row align-items-center mb-3">
            <div class="col-12 col-lg">
              <label for="site-vat" class="custom-label"
                >Varsayılan KDV Oranı</label
              >
              <div class="d-block">
                <small class="text-muted"
                  >Site geneli uygulanacak olan KDV değeri</small
                >
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <WeInput
                type="text"
                v-bind:formGroup="false"
                id="site-vat"
                v-model="data.vat"
              />
            </div>
          </div>
        </div>
        <!-- VAT -->

        <!-- VAT INCLUDE & EXCLUDE -->
        <div class="col-12 col-lg-6">
          <div class="form-row align-items-center mb-3">
            <div class="col-12 col-lg">
              <label class="custom-label">Site Geneli KDV</label>
              <div class="d-block">
                <small class="text-muted"
                  >Site geneli ürünlere uygulanacak olan KDV tipi
                  (Dahil/Hariç)</small
                >
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <v-select
                v-model="data.vat_type"
                label="name"
                v-bind:options="vatOptions"
                v-bind:reduce="(opt) => opt.id"
                v-bind:clearable="false"
                class="setting-select"
              >
              </v-select>
            </div>
          </div>
        </div>
        <!-- VAT INCLUDE & EXCLUDE -->

        <div class="col-12 col-lg-6">
          <!-- Show Out of Stock -->
          <div class="form-row align-items-center mb-3">
            <div class="col-12 col-lg">
              <label class="custom-label">Stokta Olmayan Ürünler</label>
              <div class="d-block">
                <small class="text-muted"
                  >Stokta olmayan ürünleri göster/gizle</small
                >
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <v-select
                v-model="data.show_out_stock"
                label="name"
                v-bind:options="options.show_out_stock"
                v-bind:reduce="(opt) => opt.id"
                v-bind:clearable="false"
                class="setting-select"
              >
              </v-select>
            </div>
          </div>
        </div>
        <!-- Show Out of Stock -->

        <!-- Show Per -->
        <div class="col-12 col-lg-6">
          <div class="form-row align-items-center mb-3">
            <div class="col-12 col-lg">
              <label class="custom-label">Gösterilecek Ürün Sayısı</label>
              <div class="d-block">
                <small class="text-muted"
                  >Listelemede gösterilecek sayfa başı ürün sayısı</small
                >
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <WeInput
                type="text"
                v-bind:formGroup="false"
                v-model="data.show_per"
              />
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="form-row align-items-center mb-3">
            <div class="col-12 col-lg">
              <label class="custom-label">Google Merchant Ürün Adeti</label>
              <div class="d-block">
                <small class="text-muted"
                  >Google merchant xml'inde sayfa başına gösterilecek ürün
                  sayısı</small
                >
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <WeInput
                type="text"
                v-bind:formGroup="false"
                v-model="data.google_merchant_page_prod_count"
              />
            </div>
          </div>
        </div>
        <!-- Show Per -->

        <!-- Currency -->
        <div class="col-12 col-lg-6">
          <div class="form-row align-items-center mb-3">
            <div class="col-12 col-lg">
              <label class="custom-label">Varsayılan Para Birimi</label>
              <div class="d-block">
                <small class="text-muted">Site para birimi</small>
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <v-select
                v-model="data.currency_code"
                label="name"
                v-bind:options="shared.currencyCodes.currencies"
                v-bind:reduce="(opt) => opt.id"
                v-bind:clearable="false"
                class="setting-select"
              >
              </v-select>
            </div>
          </div>
        </div>
        <!-- Currency -->

        <!-- Payment Type -->
        <div class="col-12 col-lg-6">
          <div class="form-row align-items-center mb-3">
            <div class="col-12 col-lg">
              <label class="custom-label">Varsayılan Ödeme Yöntemi</label>
            </div>
            <div class="col-12 col-lg-4">
              <v-select
                v-model="data.payment_type"
                label="name"
                v-bind:options="shared.paymentTypes.list"
                v-bind:reduce="(opt) => opt.id"
                v-bind:clearable="false"
                class="setting-select"
              >
              </v-select>
            </div>
          </div>
        </div>
        <!-- Payment Type -->

        <!-- Quantity Type -->
        <div class="col-12 col-lg-6">
          <div class="form-row align-items-center mb-3">
            <div class="col-12 col-lg">
              <label class="custom-label">Varsayılan Stok Tipi</label>
            </div>
            <div class="col-12 col-lg-4">
              <v-select
                v-model="data.stock_type"
                label="name"
                v-bind:options="shared.quantityTypes.list"
                v-bind:reduce="(opt) => opt.id"
                v-bind:clearable="false"
                class="setting-select"
              >
              </v-select>
            </div>
          </div>
        </div>
        <!-- Quantity Type -->

        <!-- Discount Type -->
        <div class="col-12 col-lg-6">
          <div class="form-row align-items-center mb-3">
            <div class="col-12 col-lg">
              <label class="custom-label">Varsayılan İndirim Türü</label>
            </div>
            <div class="col-12 col-lg-4">
              <v-select
                v-model="data.discount_type"
                label="name"
                v-bind:options="shared.discountTypes.list"
                v-bind:reduce="(opt) => opt.id"
                v-bind:clearable="false"
                class="setting-select"
              >
              </v-select>
            </div>
          </div>
        </div>
        <!-- Discount Type -->

        <!-- Product Order -->
        <div class="col-12 col-lg-6">
          <div class="form-row align-items-center mb-3">
            <div class="col-12 col-lg">
              <label class="custom-label">Varsayılan Ürün Sıralama</label>
              <div class="d-block">
                <small class="text-muted"
                  >Ürün listesinde varsayılan sıralama</small
                >
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <v-select
                v-model="data.product_sort_by"
                label="name"
                v-bind:options="productSortingOptions"
                v-bind:reduce="(opt) => opt.id"
                v-bind:clearable="false"
                class="setting-select"
                v-on:input="changeSortOption"
              >
              </v-select>
              <div class="d-block mt-2">
                <v-select
                  v-if="data.product_sort_by"
                  v-model="data.product_sort_direction"
                  label="name"
                  v-bind:options="productSortingDirections"
                  v-bind:reduce="(opt) => opt.id"
                  v-bind:clearable="false"
                  class="setting-select"
                  v-show="isSortingDirections"
                >
                </v-select>
              </div>
            </div>
          </div>
        </div>
        <!-- Product Order -->

        <!-- Default Member Group -->
        <div class="col-12 col-lg-6">
          <div class="form-row align-items-center mb-3">
            <div class="col-12 col-lg">
              <label class="custom-label">Varsayılan Üye Grubu</label>
            </div>
            <div class="col-12 col-lg-4">
              <v-select
                v-model="data.member_group"
                label="name"
                v-bind:options="membergroup.list"
                v-bind:reduce="(opt) => opt.id"
                v-bind:clearable="false"
                class="setting-select"
              >
              </v-select>
            </div>
          </div>
        </div>

        <!-- Default Member Group -->

        <!-- Search -->
        <div class="col-12 col-lg-6">
          <div class="form-row align-items-center mb-3">
            <div class="col-12 col-lg">
              <label class="custom-label">Varsayılan Arama Sıralaması</label>
            </div>
            <div class="col-12 col-lg-4">
              <WeRowInput
                class="setting-select"
                labelClass="display-none"
                inputClass="col-12 col-lg-12"
                form="vSelect"
                v-bind:clearable="true"
                name="order_statuses"
                placeholder="Sipariş
              Durumu"
                v-bind:multiple="true"
                v-bind:close-on-select="false"
                v-bind:option-prop="searchOptions"
                v-model="data.search_index"
              />
            </div>
          </div>
        </div>
        <!-- Search -->

        <!-- Default Member Group -->
        <div class="col-12 col-lg-6">
          <div class="form-row align-items-center mb-3">
            <div class="col-12 col-lg">
              <label class="custom-label">Varsayılan Site Dili</label>
            </div>
            <div class="col-12 col-lg-4">
              <v-select
                label="name"
                class="setting-select"
                v-bind:options="getAvailableLocales"
                v-bind:reduce="(opt) => opt.id"
                v-bind:clearable="false"
                v-model="data.default_locale"
              />
            </div>
          </div>
        </div>
        <!-- Default Member Group -->

        <div class="col-12 col-lg-6">
          <div class="form-row align-items-center mb-3">
            <div class="col-12 col-lg-4">
              <WeInput
                formGroup="form-group mb-0"
                name="search_products_count"
                label="Ürün Sayısı"
                v-model="data.search_products_count"
                type="number"
                v-bind:filterNumber="true"
                v-bind:minValue="1"
              />
            </div>
            <div class="col-12 col-lg-4">
              <WeInput
                formGroup="form-group mb-0"
                name="search_brands_count"
                label="Marka Sayısı"
                v-model="data.search_brands_count"
                type="number"
                v-bind:filterNumber="true"
                v-bind:minValue="1"
              />
            </div>

            <div class="col-12 col-lg-4">
              <WeInput
                formGroup="form-group mb-0"
                label="Kategori Sayısı"
                name="search_categories_count"
                v-model="data.search_categories_count"
                type="number"
                v-bind:filterNumber="true"
                v-bind:minValue="1"
              />
            </div>
          </div>
        </div>
      </div>
    </WeCard>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import LangFlag from "vue-lang-code-flags";

export default {
  name: "General",
  components: {
    LangFlag,
  },
  data() {
    return {
      isSortingDirections: true,
      data: {
        cdn_enable: this.settings["app.cdn_enable"] == "1" ? 1 : 0,
        erp_integration: this.settings["site.erp_integration"] == "1" ? 1 : 0,
        marketplace_integration:
          this.settings["site.marketplace_integration"] == "1" ? 1 : 0,
        invoice_integration:
          this.settings["site.invoice_integration"] == "1" ? 1 : 0,
        auto_exchange_rate:
          this.settings["site.auto_exchange_rate"] == "1" ? 1 : 0,
        credit_card: this.settings["site.credit_card"] == "1" ? 1 : 0,
        bank_transfer: this.settings["site.bank_transfer"] == "1" ? 1 : 0,
        show_price: this.settings["site.show_price"] == "1" ? 1 : 0,
        api_update_price: this.settings["site.api_update_price"] == "1" ? 1 : 0,
        price_api_url: this.settings["site.price_api_url"],
        api_price_limit: this.settings["site.api_price_limit"],
        // insurance_fixed_cost: this.settings["site.insurance_fixed_cost"] || 0,
        show_tax_excluded:
          this.settings["site.show_tax_excluded"] == "1" ? 1 : 0,
        vat: this.settings["site.vat"],
        vat_type: this.settings["site.vat_type"] || "excluded",
        sales_contract: this.settings["site_sales_contract"] == "1" ? 1 : 0,
        show_out_stock: this.settings["site.show_out_stock"],
        order_out_stock: this.settings["site.order_out_stock"] == "1" ? 1 : 0,
        show_per: this.settings["site.show_per"],
        google_merchant_page_prod_count:
          this.settings["site.google_merchant_page_prod_count"],
        comment: this.settings["site.comment"] == "1" ? 1 : 0,
        comment_member: this.settings["site.comment_member"] == "1" ? 1 : 0,
        point: this.settings["site.point"] == "1" ? 1 : 0,
        status: this.settings["site.status"] == "1" ? 1 : 0,
        mobile: this.settings["site.mobile"] == "1" ? 1 : 0,
        not_image: this.settings["site.not_image"] == "1" ? 1 : 0,
        search_products_count:
          this.settings["site.search_products_count"] ?? 10,
        search_categories_count:
          this.settings["site.search_categories_count"] ?? 10,
        search_brands_count: this.settings["site.search_brands_count"] ?? 10,
        search_index: this.settings["site.search_index"]
          ? JSON.parse(this.settings["site.search_index"]).length > 0
            ? JSON.parse(this.settings["site.search_index"])
            : { id: "products", name: "Ürün" }
          : { id: "products", name: "Ürün" },
        show_delivery_zone:
          this.settings["site.show_delivery_zone"] == "1" ? 1 : 0,
        show_featured_product:
          this.settings["site.show_featured_product"] == "1" ? 1 : 0,
        show_new_product: this.settings["site.show_new_product"] == "1" ? 1 : 0,
        kvkk: this.settings["site.kvkk"] == "1" ? 1 : 0,
        cash_on_delivery: this.settings["site.cash_on_delivery"] == "1" ? 1 : 0,
        currency_code: this.settings["site.currency_code"],
        discount_type: this.settings["site.discount_type"],
        payment_type: this.settings["site.payment_type"],
        stock_type: this.settings["site.stock_type"],
        promotion_type: this.settings["site.promotion_type"],
        product_sort_by: this.settings["site.product_sort_by"] || "created_at",
        product_sort_direction:
          this.settings["site.product_sort_direction"] || "DESC",
        product_variants: this.settings["site.product_variants"] == "1" ? 1 : 0,
        show_feature_values:
          this.settings["site.show_feature_values"] == "1" ? 1 : 0,
        use_coupon: this.settings["site.use_coupon"] == "1" ? 1 : 0,
        slug_prefix: this.settings["site.slug_prefix"] == "1" ? 1 : 0,
        member_group: this.settings["site.default_group_id"] || null,
        show_marketplace_price:
          this.settings["site.show_marketplace_price"] == "1" ? 1 : 0,
        micro_site_status:
          this.settings["site.micro_site_status"] == "1" ? 1 : 0,
        default_locale: this.settings["site.default_locale"] || "tr",
      },
      options: {
        show_out_stock: [
          {
            id: "show",
            name: "Göster",
          },
          {
            id: "hide",
            name: "Gizle",
          },
          {
            id: "last",
            name: "En Son Listele",
          },
        ],
      },
      searchOptions: [
        {
          id: "products",
          name: "Ürün",
        },
        {
          id: "categories",
          name: "Kategori",
        },
        {
          id: "brands",
          name: "Marka",
        },
      ],
      vatOptions: [
        {
          id: "included",
          name: "Dahil",
        },
        {
          id: "excluded",
          name: "Hariç",
        },
      ],
      productSortingOptions: [
        {
          id: "created_at",
          name: "En Yeniler",
        },
        {
          id: "brand",
          name: "Marka",
        },
        {
          id: "price",
          name: "Fiyat",
        },
        {
          id: "name",
          name: "Ürün Adı",
        },
      ],
      productSortingDirections: [
        {
          id: "ASC",
          name: "Artan",
        },
        {
          id: "DESC",
          name: "Azalan",
        },
      ],
    };
  },
  props: {
    settings: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    ...mapActions({
      getMemberGroups: "membergroup/getList",
    }),
    changeSortOption(val) {
      switch (val) {
        case "created_at":
          this.data.product_sort_direction =
            this.productSortingDirections[1].id;
          this.isSortingDirections = false;
          break;
        case "name":
        case "brand":
          this.productSortingDirections[0].name = "A-Z Sırala";
          this.productSortingDirections[1].name = "Z-A Sırala";
          break;
        default:
          this.productSortingDirections[0].name = "Artan";
          this.productSortingDirections[1].name = "Azalan";
          this.isSortingDirections = true;
          break;
      }
      if (val == "created_at") {
        this.data.product_sort_direction = this.productSortingDirections[1].id;
        this.isSortingDirections = false;
      } else {
        this.isSortingDirections = true;
      }
    },
    onSave() {
      delete this.settings["app.cdn_enable"];
      delete this.settings["site.erp_integration"];
      delete this.settings["site.marketplace_integration"];
      delete this.settings["site.workmanship_api_url"];
      delete this.settings["site.insurance_fixed_cost"];
      delete this.settings["site.show_marketplace_price"];
      this.settings["site.auto_exchange_rate"] = this.data.auto_exchange_rate;
      this.settings["site.credit_card"] = this.data.credit_card;
      this.settings["site.bank_transfer"] = this.data.bank_transfer;
      this.settings["site.show_price"] = this.data.show_price;
      this.settings["site.api_update_price"] = this.data.api_update_price;
      this.settings["site.price_api_url"] = this.data.api_update_price
        ? this.data.price_api_url
        : null;
      this.settings["site.api_price_limit"] = this.data.api_price_limit;
      this.settings["site.show_tax_excluded"] = this.data.show_tax_excluded;
      this.settings["site.vat"] = this.data.vat;
      this.settings["site.vat_type"] = this.data.vat_type;
      this.settings["site_sales_contract"] = this.data.sales_contract;
      this.settings["site.micro_site_status"] = this.data.micro_site_status;
      this.settings["site.search_products_count"] =
        this.data.search_products_count;
      this.settings["site.search_categories_count"] =
        this.data.search_categories_count;
      this.settings["site.search_brands_count"] = this.data.search_brands_count;

      this.settings["site.search_index"] = JSON.stringify(
        this.data.search_index
      );
      this.settings["site.show_out_stock"] = this.data.show_out_stock;
      this.settings["site.order_out_stock"] = this.data.order_out_stock;
      this.settings["site.show_per"] = this.data.show_per;
      this.settings["site.google_merchant_page_prod_count"] =
        this.data.google_merchant_page_prod_count;
      this.settings["site.comment"] = this.data.comment;
      this.settings["site.comment_member"] = this.data.comment
        ? this.data.comment_member
        : 0;
      this.settings["site.point"] = this.data.point;
      this.settings["site.status"] = this.data.status;
      this.settings["site.mobile"] = this.data.mobile;
      this.settings["site.not_image"] = this.data.not_image;
      this.settings["site.show_delivery_zone"] = this.data.show_delivery_zone;
      this.settings["site.show_featured_product"] =
        this.data.show_featured_product;
      this.settings["site.show_new_product"] = this.data.show_new_product;
      this.settings["site.kvkk"] = this.data.kvkk;
      this.settings["site.cash_on_delivery"] = this.data.cash_on_delivery;
      this.settings["site.currency_code"] = this.data.currency_code;
      this.settings["site.discount_type"] = this.data.discount_type;
      this.settings["site.payment_type"] = this.data.payment_type;
      this.settings["site.stock_type"] = this.data.stock_type;
      this.settings["site.promotion_type"] = this.data.promotion_type;
      this.settings["site.product_sort_by"] = this.data.product_sort_by;
      this.settings["site.product_sort_direction"] =
        this.data.product_sort_direction;
      this.settings["site.product_variants"] = this.data.product_variants;
      this.settings["site.show_feature_values"] = this.data.show_feature_values;
      this.settings["site.use_coupon"] = this.data.use_coupon;
      this.settings["site.default_group_id"] = this.data.member_group;
      this.settings["site.slug_prefix"] = this.data.slug_prefix;
      this.settings["site.default_locale"] = this.data.default_locale;
      this.$parent.$emit("on-save", this.settings);
    },
  },
  computed: {
    ...mapState(["shared", "membergroup", "session"]),
    getAvailableLocales() {
      const languageData = {
        tr: {
          id: "tr",
          name: "Türkçe",
        },
        en: {
          id: "en",
          name: "English",
        },
        de: {
          id: "de",
          name: "German",
        },
        ru: {
          id: "ru",
          name: "Ruassian",
        },
        ar: {
          id: "ar",
          name: "Arabic",
        },
      };
      let languages = this.session?.config["site.available_locales"];

      if (languages) {
        languages = JSON.parse(languages);
      } else {
        languages = ["tr"];
      }

      return languages.map((item) => {
        return languageData[item];
      });
    },
  },
  mounted() {
    this.changeSortOption(this.data.product_sort_by);
    this.getMemberGroups();
  },
};
</script>
<style scoped>
.dispaly-none {
  display: none;
}
</style>